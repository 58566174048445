import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getDatabase, ref, get, remove } from "@firebase/database";
import firebaseApp, { KEY } from "../../integration/FirebaseConfig";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { tokens } from "../../theme";

const database = getDatabase(firebaseApp);

const Secretarios = () => {
  const [secretarios, setSecretarios] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSecretario, setSelectedSecretario] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 2,
      cellClassName: "name-column--cell",
      fontWeight: "bold",
    },
    {
      field: "cargo",
      headerName: "Cargo",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "acoes",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleEdit(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClick(row)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
          >
            <DeleteOutlineIcon /> EXCLUIR
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const secretariosRef = ref(database, `db/cidades/${KEY}/secretarios`);
    get(secretariosRef)
      .then((snapshot) => {
        const data = snapshot.val();
       if (data) {
          const lista = Object.keys(data).map((key) => ({ id: key, ...data[key] }));
        setSecretarios(lista);
        } else {
          setSecretarios([]);
        }
      })
      .catch((error) => {
        console.error("Erro ao obter dados:", error);
      });
  }, []);

  const handleEdit = (secretario) => {
    console.log("Passando dados do secretário: ", secretario); // Verifique se os dados estão corretos aqui
    navigate(`/${KEY}/secretarios/editar`, { state: { userData: secretario } });
  };
  

  const handleDeleteClick = (secretario) => {
    setSelectedSecretario(secretario);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedSecretario) {
      const secretarioRef = ref(database, `db/cidades/${KEY}/secretarios/${selectedSecretario.id}`);
      remove(secretarioRef)
        .then(() => {
          setOpenDialog(false);
          setSelectedSecretario(null);
        })
        .catch((error) => {
          console.error("Erro ao excluir o secretário:", error);
        });
    }
  };

  return (
    <Box m="20px">
      <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3}>
        <Typography variant={isMobile ? "h4" : "h2"} color="white" fontWeight="bold" flexGrow={1}>
          SECRETÁRIOS
        </Typography>
        <Link to={`/${KEY}/secretarios/novo`}>
          <IconButton variant="contained" style={{ backgroundColor: "#0F71FD", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }}>
            <AddBoxIcon style={{ marginRight: 4 }} /> NOVO
          </IconButton>
        </Link>
      </Box>
      <Box className="watermark-container"
        m="30px 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${colors.primary[500]}`,
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: '#1D1D1D',
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#212121",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          ".MuiDataGrid-columnSeparator": {
            color: '#707070 !important'
          }
        }}
      >
        <DataGrid
          rows={secretarios}
          columns={columns}
          rowHeight={80}
          hideFooter={true}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir este secretário?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} style={{ border: "1px solid #0F71FD", color: "white" }}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} style={{ backgroundColor: "#0F71FD", color: "white" }}>Excluir</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Secretarios;
