import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { getDatabase, ref, push } from "firebase/database";
import firebaseApp, { KEY, KEY_SITE } from '../../../integration/FirebaseConfig';
const database = getDatabase(firebaseApp);

const NovaLicitacaoArquivo = () => {
  const [titulo, setTitulo] = useState('');
  const [data, setData] = useState('');
  const [descricao, setDescricao] = useState('');
  const [numero, setNumero] = useState('');
  const [modalidade, setModalidade] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [files, setFiles] = useState({ publicidade: null, contrato: null, edital: null, outros: null });
  const navigate = useNavigate();

  const handleFileChange = (e, type) => {
    setFiles((prevFiles) => ({ ...prevFiles, [type]: e.target.files[0] }));
  };

  const modalidadeConst = ["CONCORRÊNCIAS", "DISPENSAS", "ADESÃO", "INEXIGIBILIDADE"];

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!files.contrato) {
      alert("É obrigatório adicionar o contrato antes de publicar.");
      return;
    }
  
    const filePaths = {};
  
    for (const key of Object.keys(files)) {
      if (files[key]) {
        const formData = new FormData();
        formData.append("file", files[key]); // Nome esperado no PHP
        formData.append("documento", key); // Nome da categoria
  
        try {
          const response = await fetch(`${KEY_SITE}upload.php`, {
            method: 'POST',
            body: formData,
          });
  
          const result = await response.json();
  
          if (result.error) {
            throw new Error(result.error);
          }
  
          filePaths[key + "Doc"] = result.filePath || "";
        } catch (error) {
          console.error(`Erro ao enviar ${key}:`, error);
          return;
        }
      }
    }
  
    try {
      const licitacoesRef = ref(database, `db/cidades/${KEY}/licitacoesDocumentos`);
      await push(licitacoesRef, {
        titulo,
        data,
        descricao,
        numero,
        modalidade,
        ativo,
        ...filePaths,
      });
  
      navigate(`/${KEY}/licitacoes/arquivo`);
    } catch (error) {
      console.error('Erro ao salvar dados no Firebase:', error);
    }
  };
  

  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', mx: 'auto', mt: '40px' }}>
      <Divider sx={{ color: "#0F71FD", mb: 2, fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
        ARQUIVOS - LICITAÇÕES
      </Divider>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth variant="outlined" label="Título" value={titulo} onChange={(e) => setTitulo(e.target.value)} required />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth variant="outlined" label="Descrição" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth variant="outlined" label="Número" value={numero} onChange={(e) => setNumero(e.target.value)} required />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="date"
              label="Data"
              InputLabelProps={{ shrink: true }}
              value={data}
              onChange={(e) => setData(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Modalidade</InputLabel>
              <Select value={modalidade} onChange={(e) => setModalidade(e.target.value)}>
                {modalidadeConst.map((mod, index) => (
                  <MenuItem key={index} value={mod}>{mod}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {Object.keys(files).map((type) => (
            <Grid item xs={12} key={type}>
              <input
                accept=".doc,.docx,.png,.jpg,.jpeg,.pdf"
                type="file"
                onChange={(e) => handleFileChange(e, type)}
              />
              <Typography>{files[type]?.name || `Selecionar arquivo - ${type.toUpperCase()}`}</Typography>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Salvar e Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NovaLicitacaoArquivo;
