import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { PhotoCamera, Delete } from "@mui/icons-material";
import { database, KEY, KEY_SITE } from '../../../integration/FirebaseConfig';
import { getDatabase, ref, push } from "firebase/database";

const secretarias = [
  { nome: "Administração", descricao: "Responsável pela gestão e organização administrativa do município, coordenando os processos internos e garantindo o funcionamento eficiente da máquina pública." },
  { nome: "Saúde", descricao: "Cuida das políticas públicas de saúde, gestão de hospitais, clínicas e unidades básicas de saúde, promovendo o bem-estar e qualidade de vida para a população." },
  { nome: "Educação", descricao: "Gerencia as escolas municipais, programas educacionais e políticas pedagógicas, garantindo acesso à educação de qualidade para todas as faixas etárias." },
  { nome: "Finanças", descricao: "Administra o orçamento municipal, realiza a gestão das receitas e despesas do município, além de planejar as finanças públicas e captar recursos para investimentos." },
  { nome: "Obras e Infraestrutura", descricao: "Cuida da execução e manutenção de obras públicas, como pavimentação, construção de prédios e infraestrutura urbana, além de planejar a expansão da cidade." },
  { nome: "Desenvolvimento Social", descricao: "Desenvolve políticas públicas voltadas para a assistência social, promoção da inclusão e dignidade da população em situação de vulnerabilidade." },
  { nome: "Esporte e Lazer", descricao: "Fomenta a prática esportiva, organiza competições e promove atividades recreativas e de lazer para a comunidade, visando a inclusão e o bem-estar da população." },
  { nome: "Cultura", descricao: "Promove e preserva as manifestações culturais locais, organiza eventos artísticos e culturais, além de zelar pela conservação do patrimônio histórico e cultural do município." },
  { nome: "Turismo", descricao: "Desenvolve ações de promoção do município como destino turístico, fomentando o setor e atraindo turistas para explorar as belezas naturais e culturais da região." },
  { nome: "Meio Ambiente", descricao: "Trabalha na proteção do meio ambiente, formulando políticas de preservação ecológica, controle da poluição e incentivo à sustentabilidade." },
  { nome: "Agricultura", descricao: "Apoia o setor agrícola, promove políticas de incentivo à agricultura sustentável e ao desenvolvimento rural, além de fomentar o agronegócio local." },
  { nome: "Segurança Pública", descricao: "Coordena as ações de policiamento, proteção e prevenção ao crime, visando garantir a ordem pública e a segurança da população." },
  { nome: "Planejamento", descricao: "Elabora estratégias de planejamento urbano e econômico, com foco no desenvolvimento sustentável, organização da cidade e qualidade de vida dos cidadãos." },
  { nome: "Habitação", descricao: "Desenvolve projetos habitacionais, promovendo o acesso à moradia digna para a população, e coordenando programas de regularização fundiária." },
  { nome: "Transporte", descricao: "Administra o transporte público, promove melhorias na mobilidade urbana e coordena políticas de acessibilidade e fluidez no trânsito." },
  { nome: "Gestão Pública", descricao: "Aprimora os processos administrativos e implementa práticas de gestão moderna, buscando otimizar os recursos e oferecer melhores serviços à população." },
  { nome: "Tecnologia e Inovação", descricao: "Gerencia o uso de tecnologias digitais e inovações tecnológicas para melhorar a qualidade e a eficiência dos serviços prestados ao município." },
  { nome: "Indústria e Comércio", descricao: "Fomenta o crescimento da indústria local e o desenvolvimento comercial, promovendo a cidade como um polo de negócios e inovação." },
  { nome: "Direitos Humanos", descricao: "Defende os direitos fundamentais de todos os cidadãos, promovendo políticas públicas que garantam a igualdade de direitos e a dignidade humana." },
  { nome: "Juventude", descricao: "Desenvolve políticas e programas voltados para o empoderamento e desenvolvimento dos jovens, oferecendo oportunidades de educação, emprego e lazer." },
  { nome: "Mulher", descricao: "Cria políticas públicas para promover a igualdade de gênero, combate à violência contra a mulher e apoio a programas de empoderamento feminino." },
  { nome: "Pessoas com Deficiência", descricao: "Trabalha pela inclusão e acessibilidade das pessoas com deficiência, garantindo seus direitos e o pleno acesso à educação, emprego e serviços públicos." },
  { nome: "Assistência Jurídica", descricao: "Oferece suporte jurídico gratuito à população em situação de vulnerabilidade, garantindo o acesso à justiça e a defesa dos direitos dos cidadãos." },
  { nome: "Defesa Civil", descricao: "Gerencia as ações de prevenção, mitigação e resposta a desastres naturais, garantindo a segurança da população em situações de emergência." },
  { nome: "Recursos Humanos", descricao: "Administra a gestão de pessoal, desenvolvendo e implementando políticas de recursos humanos, capacitação e bem-estar dos servidores públicos municipais." },
  { nome: "Assistência Social", descricao: "Desenvolve ações e programas voltados para o atendimento e apoio às famílias e indivíduos em situação de risco social, promovendo a inclusão e a cidadania." },
  { nome: "Trânsito", descricao: "Planeja, organiza e fiscaliza o trânsito urbano, além de coordenar campanhas educativas e ações de segurança viária para garantir a fluidez e segurança nas vias públicas." }
];


const NovoSecretario = () => {
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [cargo, setCargo] = useState('');
  const [email, setEmail] = useState('contato@example.com');
  const [telefone, setTelefone] = useState('55 83 0000 0000');
  const [imagem, setImagem] = useState(null);
  const navigate = useNavigate();

  const handleCargoChange = (event) => {
    const selectedCargo = event.target.value;
    setCargo(selectedCargo);
    const secretaria = secretarias.find(sec => sec.nome === selectedCargo);
    setDescricao(secretaria ? secretaria.descricao : '');
  };

  const handleImageChange = (e) => {
    setImagem(e.target.files[0]);
  };

  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('title', nome);

    const response = await fetch(`${KEY_SITE}upload_image.php`, {
      method: 'POST',
      body: formData,
    });

    const result = await response.text();
    if (!response.ok) {
      throw new Error(result || 'Erro ao enviar a imagem');
    }

    return result;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const imagemUrl = imagem ? await uploadImage(imagem) : 'https://upload.wikimedia.org/wikipedia/commons/0/03/Sem_imagem.jpg';
      const secretario = {
        nome,
        descricao,
        cargo,
        email,
        telefone,
        imagem: imagemUrl,
      };

      const newsRef = ref(database, `db/cidades/${KEY}/secretarios/`);
      await push(newsRef, secretario);
      console.log("Secretário cadastrado:", secretario);
      navigate(-1); 
    } catch (error) {
      console.error('Erro ao salvar o Secretário:', error);
      alert('Erro ao salvar o Secretário');
    }
  };



  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px">
      <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", padding: "20px", fontWeight: 'bold', fontSize: '22px' }}>
        CADASTRAR NOVO SECRETÁRIO
      </Divider>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Nome" value={nome} onChange={(e) => setNome(e.target.value)} required />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth select label="Cargo" value={cargo} onChange={handleCargoChange} required>
              {secretarias.map((sec, index) => (
                <MenuItem key={index} value={sec.nome}>{sec.nome}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Descrição" value={descricao} onChange={(e) => setDescricao(e.target.value)} multiline rows={5} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} type="email" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} required />
          </Grid>
          <Grid item xs={12}>
            <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file" onChange={handleImageChange} />
            <label htmlFor="icon-button-file">
              <IconButton color="primary" component="span">
                <PhotoCamera />
                <Typography variant="h6" color="#FFF" marginLeft={1}> Selecionar Imagem</Typography>
              </IconButton>
            </label>
            {imagem && (
              <List>
                <ListItem>
                  <ListItemIcon>
                    <IconButton edge="end" onClick={() => setImagem(null)}>
                      <Delete />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={imagem.name} />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, bgcolor: "#0F71FD" }}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NovoSecretario;
