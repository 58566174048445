import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert, Select, MenuItem, Backdrop, Box } from '@mui/material';
import firebaseApp from '../../integration/FirebaseConfig';
import { getDatabase, ref, query, equalTo, get, onValue, orderByChild, set, update } from 'firebase/database';
import { GoogleAuthProvider } from "firebase/auth";
import { Helmet } from 'react-helmet';
import './Login.css';
import { generateToken } from '../../services/script';

const database = getDatabase(firebaseApp);


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [cidades, setCidades] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const database = getDatabase();
    const cidadesRef = ref(database, 'codigo_cidade');

    onValue(cidadesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const citiesArray = Object.entries(data)
          .map(([key, value]) => ({
            id: key,
            codigo: value.codigo,
            nome: value.nome,
            ativo: value.ativo,
            site: value.site,
            bloqueado: value.bloqueado
          }))
          .filter(city => city.ativo === true);
        setCidades(citiesArray);
      }
    });
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const cidadedousuario = selectedCity;
    setLoading(true);


    /*  e.preventDefault();
     const cidadedousuario = selectedCity;
     setLoading(true);
 
     try {
       const emailTratado = email.toLocaleLowerCase();
       const database = getDatabase();
       const usuariosRef = ref(database, 'usuarios');
       const usuarioQuery = query(usuariosRef, orderByChild('email'), equalTo(emailTratado));
       const snapshot = await get(usuarioQuery);
 
 
       if (snapshot.exists()) {
 
         const data = snapshot.val();
         const usuario = Object.values(data)[0];
         if (usuario.cidade === cidadedousuario) { */

    try {
      const emailTratado = email.toLocaleLowerCase();
      const database = getDatabase();
      const usuariosRef = ref(database, 'usuarios');

      const usuarioQuery = query(usuariosRef, orderByChild('email'), equalTo(emailTratado));
      const snapshot = await get(usuarioQuery);


      if (snapshot.exists()) {
        const data = snapshot.val();
        const usuario = Object.values(data)[0];


        if (usuario.cidade === selectedCity || (usuario.cidades && usuario.cidades.includes(selectedCity)) || usuario.master === true) {

          if (usuario) {
            if (!usuario.ativo) {
              setError("Seu usuário está bloqueado.");
              setLoading(false);
            } else {


              const auth = getAuth();
              const userCredential = await signInWithEmailAndPassword(auth, emailTratado, password);
              const user = userCredential.user;
              const uid = user.uid;


              if (!usuario.licensa) {
                const licensa = generateToken(15)

                await update(
                  ref(database, `usuarios/${uid}`),
                  { licensa: licensa }
                );
              }

              const now = new Date();
              const horaLogin = now.toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              });

              const cidadeSelecionada = cidades.find(cidade => cidade.codigo === selectedCity);

              if (cidadeSelecionada) {
                await update(ref(database, `usuarios/${auth.currentUser.uid}`), {
                  cidade: cidadeSelecionada.codigo,
                  site: cidadeSelecionada.site,
                  KEY_NAME: cidadeSelecionada.nome,
                  horaLogin: horaLogin
                });
              }


              setTimeout(() => {
                setLoading(false);
                navigate(`/${cidadedousuario}`);
              }, 1000);
            }

          }
        } else {
          setError("Acesso negado a cidade escolhida.");
          setLoading(false);
        }

      } else {
        setError("Endereço de e-mail não existente ou incorreto.");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Erro durante o login:", error);
      setError("Usuario ou senha incorreto.");
    }
  };


  return (
    <>


      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/gurjao.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>


      <Helmet>
        <title>Painel - Acesso</title>
      </Helmet>

      <div className="login-container">
        <div className="logo-container">
          <img src="/images/logobs.png" alt="Logo" width="250" />
        </div>
        <div className="login-form">
          <h1>ADMINISTRATIVO</h1>

          <div className="input-group"  >
            <p className="input-legend" style={{ textAlign: "center" }}>Selecione o seu municipio</p>
            <label></label>
            <Select
              className="city-select"
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              style={{ width: 300 }}
            >
              {cidades.map((cidade) => (
                <MenuItem key={cidade.codigo} value={cidade.codigo}>{cidade.nome} - {cidade.codigo}</MenuItem>

              ))}
            </Select>
          </div>


          {selectedCity && (
            <>
              <form onSubmit={handleLogin}>
                <div className="input-group">
                  <label>E-mail:</label>
                  <input
                    type="text"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Senha:</label>
                  <input
                    type="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <p style={{ textAlign: 'end' }}>
                  <Link style={{ color: '#0F71FD', textAlign: 'end', textDecoration: 'none' }} to="#">Esqueci a senha</Link>
                </p>

                <button className="login-button" type="submit">Entrar</button>

              </form>

              <p style={{ textAlign: 'center' }}>
                Não tem uma conta? <Link style={{ color: '#0F71FD', textDecoration: 'none' }} to="/cadastro">Cadastrar</Link>
              </p>
            </>
          )}


        </div>
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Login;
