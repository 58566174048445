import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getDatabase, ref, onValue, remove, update } from '@firebase/database';
import firebaseApp, { KEY } from '../../integration/FirebaseConfig';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Helmet } from "react-helmet";
import AddBoxIcon from '@mui/icons-material/AddBox';

const database = getDatabase(firebaseApp);

const Licitacoes = () => {
  const [userData, setUserData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [modalidadeType,  setModalidadeType] = useState('TODAS');
  const [ativoStates, setAtivoStates] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();

  const handleModalidadeType = (event) => {
    setModalidadeType(event.target.value);
  };

  const modalidadeConst = [
    'TODAS','PREGÃO ELETRÔNICO', 'PREGÃO PRESENCIAL', 'TOMADA DE PREÇOS',
    "CHAMADA PÚBLICA", "CONCORRÊNCIA", "CHAMAMENTO PÚBLICO"
  ];

  const columns = [
    {
      field: "titulo",
      headerName: "Titulo",
      flex: 2,
      cellClassName: "name-column--cell",
      fontWeight: 'bold',
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "modalidade",
      headerName: "Modalidade",
      flex: 1,
      filterable: true,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => params.value.toUpperCase(),
    },


    {
      field: "data",
      headerName: "Data",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },



    {
      field: "edit",
      headerName: "Ações",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="success"
            checked={row.ativo}

            onChange={() => toggleAtivo(row.id)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
          />

          <IconButton
            onClick={() => handleDeleteClick(row)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
          >
            <DeleteOutlineIcon /> EXCLUIR
          </IconButton>
        </Box>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "titulo",
      headerName: "Titulo",
      flex: 1,
      cellClassName: "name-column--cell",
      fontWeight: 'bold',
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },

    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="success"
            checked={row.ativo}

            onChange={() => toggleAtivo(row.id)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
          />

          <IconButton
            onClick={() => handleDeleteClick(row)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
          >
            <DeleteOutlineIcon /> EXCLUIR
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    try {
      const usersRef = ref(database, `db/cidades/${KEY}/licitacoes`);
  
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dados = Object.keys(data)
            .map((key) => ({
              id: key,
              ...data[key],
            }))
            .filter((licitacao) =>
              modalidadeType === "TODAS"
                ? true 
                : licitacao.modalidade === modalidadeType
            );
  
          dados.sort((a, b) => b.timestamp - a.timestamp);
          dados.reverse();
  
          setUserData(dados);
  
          const ativoStatesInitial = dados.reduce((acc, news) => {
            acc[news.id] = news.ativo;
            return acc;
          }, {});
          setAtivoStates(ativoStatesInitial);
        } else {
          setUserData([]);
        }
      });
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  }, [modalidadeType]);
  



  const toggleAtivo = (id) => {
    setAtivoStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    const noticiasRef = ref(database, `db/cidades/${KEY}/licitacoes/${id}`);

    update(noticiasRef, { ativo: !ativoStates[id] });
  };

  const handleDeleteClick = (news) => {
    setSelectedNews(news);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedNews) {
      const newsRef = ref(database, `db/cidades/${KEY}/licitacoes/${selectedNews.id}`);
      remove(newsRef)
        .then(() => {
          setOpenDialog(false);
          setSelectedNews(null);
        })
        .catch((error) => {
          console.error('Erro ao excluir a notícia:', error);
        });
    }
  };

  return (
    <>

      <Helmet>
        <title>Painel - Licitações</title>
      </Helmet>

      <Box m="20px">
        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/bs.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          {!isMobile ? (<> <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            AVISOS LICITAÇÕES 
          </Typography></>) : (<> <Typography
            variant="h4"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
           AVISOS LICITAÇÕES 
          </Typography></>)}


          <Link to={`/${KEY}/licitacoes/novo`}>
            <IconButton variant="contained" style={{ backgroundColor: "#0F71FD", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }}>
              <AddBoxIcon style={{ marginRight: 4 }} /> NOVO
            </IconButton>
          </Link>

        </Box>

        <Box display="flex" alignItems="center" backgroundColor="#1d1d1d">

          <FormControl variant="filled" fullWidth>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Buscar por modalidade</InputLabel>
            <Select
              value={modalidadeType}
              onChange={handleModalidadeType}
              inputProps={{
                id: 'document-type-select',
              }}
              style={{ color: colors.grey[100] }}
            >

              {modalidadeConst.map((modalidade, index) => (

                <MenuItem key={index} value={modalidade}>{modalidade}</MenuItem>
              ))}
            
            </Select>
          </FormControl>

        </Box>


        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columns}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        ) : (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columnsMobile}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        )}
      </Box >

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir este aviso de licitação?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            style={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              color: 'white',
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            style={{
              backgroundColor: "#0F71FD",
              fontWeight: 'bold',
              color: 'white',
            }}
            color="error"
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Licitacoes;
